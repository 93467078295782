import { render, staticRenderFns } from "./ComprarInfo.vue?vue&type=template&id=5fe46872&scoped=true"
import script from "./ComprarInfo.vue?vue&type=script&lang=js"
export * from "./ComprarInfo.vue?vue&type=script&lang=js"
import style0 from "./ComprarInfo.vue?vue&type=style&index=0&id=5fe46872&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fe46872",
  null
  
)

export default component.exports
<template>
	<div class="select-check-box">
		<h2>{{ label }}</h2>
		<fieldset :disabled="disabled">
			<div
				v-for="(item, index) in options"
				:key="index"
				background="transparent"
				class="checkbox-box"
			>
				<div>
					<div class="block py-5 px-3 radio-select">
						<b-radio
							v-model="valueSelect"
							:name="name"
							:native-value="item"
						>
							{{ item }}
						</b-radio>
					</div>
				</div>
			</div>
		</fieldset>
		<p
			v-if="errors.length > 0"
			class="help is-danger"
		>
			{{ errors[0] }}
		</p>
	</div>
</template>

<script>
export default {
	name: 'SelectCheckBoxEBRADI',
	props: {
		label: {
			type: String,
			required: true,
			default: ''
		},
		name: {
			type: String,
			required: true,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		options: {
			type: Array,
			required: true,
			default: () => []
		},
		value: {
			type: [String, Number],
			required: false,
			default: null
		},
		errors: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	data() {
		return {
			valueSelect: null
		}
	},
	watch: {
		value(value) {
			this.valueSelect = value
		},
		valueSelect(value) {
			this.$emit('input', value)
		}
	},
}
</script>

<style lang="scss" scoped>
.select-check-box {
	margin: 42px 0;

	fieldset {
		padding: 22px 0;
		display: flex;
		gap: 20px;
		flex-wrap: wrap;
		justify-content: flex-start;

		& > div {
			min-width: 193px;
			width: auto;
			border-left: solid 1px var(--turquoise-blue);
			border-radius: 12px;
		}
	}

}

.radio-select {
	&::v-deep .b-radio.radio {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
		transition: 0.4s;

		&:hover {
			.check {
				border-color: var(--primary)!important;
			}
		}

		.control-label {
			font-size: 14px;
			line-height: 16px;
			font-weight: 700;
		}

		input:checked {
			& + .check {
				border-color: var(--primary)!important;

				&::before {
					background: var(--primary)!important;
				}
			}
		}

		.check {
			transition: 0.4s;
			margin-left: 42px;

			&:checked {
				border-color: var(--primary)!important;
			}
		}
	}
}

@media screen and (max-width: 900px)  {
	.select-check-box {
		fieldset {
			justify-content: space-between;

			& > div {
				min-width: 160px;
			}
		}
	}

	.radio-select {
		&::v-deep .b-radio.radio {
			.check {
				margin-left: 18px;
			}
		}
	}
}

</style>

<template>
	<ValidationObserver
		ref="form_dados_pessoais"
	>
		<form
			class="form-dados-pessoais"
			@submit="() => false"
		>
			<h2 class="titulo">
				Dados pessoais
			</h2>
			<fieldset
				v-if="!view"
			>
				<div class="colunas-inputs">
					<div class="coluna">
						<ValidationProvider
							v-slot="{ errors }"
							name="Nome"
							rules="required|max:128|min:8"
						>
							<b-field
								label="Nome"
								:message="(errors.length > 0) ? errors[0] : 'Nome como consta no seu documento de identidade'"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input
									v-model="form.name"
									data-testeid="nomeCompleto"
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div class="coluna">
						<ValidationProvider
							v-slot="{ errors }"
							name="Telefone"
							rules="required"
						>
							<b-field
								label="Telefone"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input
									v-model="form.phone"
									v-mask="['(##) ####-####', '(##) # ####-####']"
									type="tel"
									data-testeid="telefone"
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div class="coluna">
						<ValidationProvider
							v-slot="{ errors }"
							name="E-mail"
							rules="required|email"
						>
							<b-field
								label="E-mail"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input
									v-model="form.email"
									type="email"
									data-testeid="email"
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div class="coluna">
						<ValidationProvider
							v-slot="{ errors }"
							name="CPF"
							rules="required|cpf"
						>
							<b-field
								label="CPF"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input
									v-model="form.cpf"
									v-mask="['###.###.###-##']"
									data-testeid="cpf"
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div class="coluna">
						<b-field
							class="label-switch"
							:class="{ 'input-social': !socialName }"
						>
							<label class="label">
								Nome Social (Opcional)
								<b-switch
									v-model="socialName"
									style="margin-left: 15px;"
									size="is-small"
									class="label-input-switch"
								/>
							</label>
							<ValidationProvider
								v-if="socialName"
								v-slot="{ errors }"
								name="Nome Social"
								rules="required"
							>
								<b-input
									v-model="form.social_name"
									data-testeid="nomeSocial"
								/>
								<p
									class="help"
									:class="{ 'is-danger': (errors.length > 0) }"
								>
									{{ (errors.length > 0) ? errors[0] : 'Nome pelo qual a pessoa se identifica e é socialmente reconhecido.' }}
								</p>
							</ValidationProvider>
						</b-field>
					</div>
				</div>
			</fieldset>
			<Card
				v-else
				bars
				background="blur"
				class="view-card-info"
				size="normal"
			>
				<div>
					<p
						v-if="form.social_name !== null"
					>
						<b>Nome Social:</b> {{ form.name }}
					</p>
					<p><b>Nome Completo:</b> {{ form.name }} </p>
					<p><b>Telefone:</b> {{ form.phone }} </p>
					<p><b>Email:</b> {{ form.email }} </p>
					<p><b>Número da matrícula:</b> {{ form.matricula }} </p>
				</div>
			</Card>
		</form>
	</ValidationObserver>
</template>

<script>
import '@commons/rules.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Card from '@ebradi/componets/Card'

export default {
	name: 'FormDadosPessoaisComponentEBRADI',
	components: {
		ValidationProvider,
		ValidationObserver,
		Card
	},
	props: {
		dados_pessoais: {
			type: Object,
			required: false
		},
		view: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			socialName: false,
			form: {
				name: null,
				phone: null,
				email: null,
				cpf: null,
				social_name: null,
				matricula: null
			}
		}
	},
	mounted() {
		if (this.dados_pessoais) {
			this.form.name = this.dados_pessoais.name;
			this.form.phone = this.dados_pessoais.phone;
			this.form.email = this.dados_pessoais.email;
			this.form.cpf = this.dados_pessoais.cpf;
			this.form.social_name = this.dados_pessoais.social_name;
			this.form.matricula = this.dados_pessoais.matricula;
		}
	},
	methods: {
		setData(key, value) {
			if (Object.keys(this.form).includes(key)) {
				this.form[key] = value
			}
		},
		getAllData() {
			return this.form
		},
		getData(key) {
			if (Object.keys(this.form).includes(key)) {
				return this.form[key]
			}

			return null
		},
		async validations() {
			return await this.$refs.form_dados_pessoais.validate()
		},
		clear() {
			this.socialName = false

			this.form = {
				name: null,
				phone: null,
				email: null,
				cpf: null,
				social_name: null,
				matricula: null
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.form-dados-pessoais {
	margin: 84px 0px;

	.titulo {
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 30px;
	}

	.input-social {
		display: flex;
		height: 100%;
	}

	.label-switch {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	&::v-deep .icon.is-right.has-text-danger {
		display: none!important;
	}
}

.view-card-info {
	max-width: 465px;
	margin: 42px 0;

	::v-deep .ebradi-card-body {
		padding: 16px 26px;
		font-size: 16px;
		line-height: 24px;

		b {
			font-weight: 700;
		}
	}
}

@media screen and (max-width: 900px)  {
	.form-dados-pessoais {
		margin: 42px 0px;

		.titulo {
			font-size: 14px;
		}

		.label-switch {
			display: flex;

			.label {
				display: flex;

				.label-input-switch {
					order: -1;
					margin-left: 0px!important;
					margin-right: 12px;
				}
			}
		}
	}

	.colunas-inputs {
		.coluna {
			width: 100%;
			max-width: 100%;
			order: 0;

			&:first-child {
				order: -2;
			}

			&:nth-child(2), &:nth-child(4) {
				order: 2;
				grid-column: auto;
				display: inline-block;
				width: calc(50% - 12px);
			}

			&:last-child {
				order: -1;
			}
		}
	}
}

</style>

<template>
	<ValidationObserver
		ref="form_inscricao"
		v-slot="{ invalid }"
	>
		<form onsubmit="return false">
			<FormDadosPessoais
				ref="form_dados_pessoais"
				:dados_pessoais="inscricao.dados_pessoais"
			/>
			<FormEndereco
				ref="form_endereco_residencial"
				label="Endereço residencial"
				:endereco="inscricao.endereco"
				testeid="enderecoResidencial"
			/>
			<div>
				<b-field>
					<b-switch
						v-model="hasCNPJ"
						data-testeid="responsavelFinanceiro"
					>
						Usar CNPJ como responsável financeiro
					</b-switch>
				</b-field>
				<b-field>
					<b-switch
						v-model="hasEnderecoCobranca"
						:disabled="hasCNPJ"
						data-testeid="enderecoDeCobranca"
					>
						Utilizar mesmo endereço para cobrança
					</b-switch>
				</b-field>
			</div>
			<FormDadosResponsavelFinanceiro
				v-if="hasCNPJ"
				ref="form_dados_responsavel_financeiro"
				:responsavel_financeiro="inscricao.responsavel_financeiro"
			/>
			<FormEndereco
				v-if="!hasEnderecoCobranca"
				ref="form_endereco_cobranca"
				label="Endereço de cobrança"
				:endereco="inscricao.endereco_cobranca"
				testeid="formEnderecoDeCobranca"
			/>
			<div class="btns">
				<b-button
					type="is-secondary"
					class="btn-custom-style"
					rounded
					outlined
					@click="handleBack"
					data-testeid="btn-voltar"
				>
					Voltar
				</b-button>
				<b-button
					type="is-secondary"
					rounded
					:disabled="isFormInvalid(invalid)"
					@click="submitForm"
					data-testeid="btn-proximo"
				>
					Próximo
				</b-button>
			</div>
		</form>
	</ValidationObserver>
</template>

<script>
import FormDadosPessoais from '@ebradi/componets/FormDadosPessoais';
import FormDadosResponsavelFinanceiro from '@ebradi/componets/FormDadosResponsavelFinanceiro';
import FormEndereco from '@ebradi/componets/FormEndereco';

import { ValidationObserver } from 'vee-validate';

export default {
	name: 'FormInscricaoComponentEBRADI',
	components: {
		FormDadosPessoais,
		FormDadosResponsavelFinanceiro,
		FormEndereco,
		ValidationObserver
	},
	props: {
		inscricao: {
			type: Object,
			required: false
		},
		handleBack: {
			type: Function,
			required: true
		},
		handleSubmit: {
			type: Function,
			required: true
		},
	},
	data() {
		return {
			hasCNPJ: false,
			hasEnderecoCobranca: true,
		}
	},
	watch: {
		hasCNPJ(value) {
			if (value === true) {
				this.hasEnderecoCobranca = false;
			} else {
				this.hasEnderecoCobranca = true;
			}
		}
	},
	mounted() {
		if (this.inscricao.responsavel_financeiro) {
			this.hasCNPJ = true;
		}
		if (this.inscricao.endereco_cobranca) {
			this.hasEnderecoCobranca = false;
		}
	},
	methods: {
		isFormInvalid(invalid) {
			return (
				invalid
			);
		},
		openModal() {
			this.showModal = true
		},
		closeModal() {
			this.showModal = false
		},
		async cupomHandle() {
			this.loadingCupom = true

			await this.handleCupom()

			this.loadingCupom = false
			this.showModal = false;
		},
		submitForm() {
			if (!this.isFormInvalid(false)) {
				const curs_id = this.$route.params.curs_id;
				const payload = {
					curs_id,
					nens_id: this.$route.params.nens_id,
					dados_pessoais: this.$refs.form_dados_pessoais?.getAllData(),
					endereco: this.$refs.form_endereco_residencial?.getAllData(),
					responsavel_financeiro: this.$refs.form_dados_responsavel_financeiro?.getAllData(),
					endereco_cobranca: this.$refs.form_endereco_cobranca?.getAllData(),
				};
				this.handleSubmit(payload);
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.btns {
	text-align: center;
	margin-top: 82px;
	margin-bottom: 22px;

	.btn-custom-style {
		margin-right: 32px;
	}

	@media (max-width: 730px) {
		.btn-custom-style {
			margin-right: 0px;
			margin-bottom: 20px;
		}

		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

</style>

<template>
	<div class="select-metodo-pagamento">
		<label>{{ label }}</label>
		<div class="metodos-pagamentos">
			<div
				v-for="(item, index) in metodosPagamento"
				:key="index"
				class="radio-select"
				:class="{'active': (item.id == valueSelect.id), 'tooltip': false}"
			>
				<div v-if="item.tooltip" class="tooltiptext">
					Pagamento com recorrência no cartão é
					uma cobrança automática mensal, assim como
					em assinaturas. Essa opção não irá consumir seu limite.
				</div>
				<inline-svg
					v-if="item.icon !== null"
					class="radio-select-icon"
					:src="item.icon"
				/>
				<div class="metodos-title">
					<span class="metodos-title-label">{{ item.label }}</span>
					<span
						v-if="item.description !== null"
						class="metodos-title-description text-active"
					>
						{{ item.description }}
					</span>
				</div>
				<b-radio
					v-model="valueSelect"
					:name="name"
					:native-value="item"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import {
	MetodoPagamento,
	MetodoPagamentoInfos,
	metodoPagamentoAtivo,
} from '@enum/checkout'


export default {
	name: 'SelectMetodoPagamentoComponentEBRADI',
	components: {
		InlineSvg
	},
	props: {
		metodos: {
			type: Array,
			required: true,
		},
		label: {
			type: String,
			required: false,
			default: ''
		},
		name: {
			type: String,
			required: true,
			default: ''
		},
		value: {
			type: Object,
			required: false,
			default: function () {
				return null
			}
		}
	},
	data() {
		return {
			valueSelect: {}
		}
	},
	computed: {
		metodosPagamento() {
			const metodos = this.metodos.filter((item) => {
					return metodoPagamentoAtivo.includes(item.oppa_id);
				}).map((item) => {
					const infos = MetodoPagamentoInfos[item.oppa_id];

					return {
						label: infos.label,
						description: item.descricao_desconto,
						id: item.oppa_id,
						icon: infos.icon,
						tooltip: item.oppa_id === MetodoPagamento.CARTAO_DE_CREDITO_RECORRENTE
					};
				});

			return metodos
		}
	},
	watch: {
		value(value) {
			this.valueSelect = value
		},
		valueSelect(value) {
			this.$emit('input', value)
		}
	}
}
</script>

<style lang="scss" scoped>
.text-active {
	color: var(--secondary-400);
}

.select-metodo-pagamento {
	margin: 24px 0px;

	label {
		color: #B2B2B2;
	}

	.metodos-pagamentos {
		margin-top: 26px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 12px;

		& > div {
			display: flex;
			justify-content: space-between;
			border: solid 1px #B2B2B2;
			width: 100%;
			max-width: 243px;
			padding: 12px 16px;
			border-radius: 12px;
			font-size: 14px;
			font-weight: 400;
			line-height: 16px;
			align-items: center;

			img {
				width: 24px;
				height: 24px;
			}

			&::v-deep .b-radio {
				margin: 0px;
			}

			.metodos-title {
				height: 100%;
				width: 100%;
				padding-left: 12px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;

				.metodos-title-label {
					font-size: 14px;
					font-weight: 600;
					line-height: 16px;
					padding-bottom: 4px;
				}

				.metodos-title-description {
					font-size: 11px;
					line-height: 16px;
				}

				span {
					display: block;
					width: 100%;
					font-size: 12px;
				}
			}
		}
	}
}

.radio-select {
	position: relative;

	&:hover {
		.tooltiptext {
			display: block;
			visibility: visible;
		}
	}

	.tooltiptext {
		display: none;
		visibility: hidden;
		position: absolute;
		background: #2D5E71;
		border-radius: 16px;
		padding: 12px;
		font-size: 14px;
		font-weight: 700;
		line-height: 20px;
		top: calc(-100% - 70px);
		left: 0px;
		width: 280px;

		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 0;
			margin: 10px;
			border-width: 15px;
			border-style: solid;
			border-color: #2D5E71 transparent transparent transparent;
		}
	}

	.radio-select-icon {
			&::v-deep svg {
				width: 24px!important;
				height: 18px!important;
			}
		}

	&.active {
		border-color: var(--secondary-400)!important;
		color: var(--secondary-400)!important;

		.radio-select-icon {
			fill: var(--secondary-400)!important;

			&::v-deep path {
				fill: var(--secondary-400)!important;
			}
		}

		.metodos-title {
			span:first-child {
				font-weight: 600;
			}
		}
	}


	&::v-deep .b-radio.radio {
		&:hover {
			.check {
				border-color: var(--secondary-400)!important;
			}
		}

		input:checked {
			& + .check {
				border-color: var(--secondary-400)!important;

				&::before {
					background: var(--secondary-400)!important;
				}
			}
		}

		.check {
			right: -10px;
			transition: 0.4s;

			&:checked {
				border-color: var(--secondary-400)!important;
			}
		}
	}
}

@media screen and (max-width: 900px)  {
	.metodos-pagamentos {
		& > div {
			width: 100% !important;
			max-width: 100% !important;
			flex-wrap: wrap;
			min-height: 104px;
			height: 100%;

			.metodos-title {
				justify-content: flex-start!important;
				order: 1;
				width: 100%;
				margin-top: 20px;
				padding-left: 0px!important;
			}
		}
	}
}
</style>

<template>
	<ValidationObserver
		ref="form_endereco"
	>
		<form
			class="form-endereco"
			@submit="() => false"
		>
			<h2 class="titulo">
				{{ label }}
			</h2>
			<fieldset 
				:disabled="loading"
				:data-testeid="testeid"
			>
				<div class="colunas-inputs">
					<div class="coluna">
						<ValidationProvider
							v-slot="{ errors }"
							name="CEP"
							rules="required|max:9|min:9"
						>
							<b-field
								:message="errors[0] || errorCep"
								:type="errors.length > 0 || errorCep ? 'is-danger' : ''"
							>
								<label class="label">
									CEP
									<span v-show="loading" class="loader_b ml-2" />
								</label>
								<b-input
									v-model="form.zipcode"
									v-mask="['#####-###']"
									data-testeid="cep"
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div
						v-if="hasSearch && !form.hasAddress"
						class="coluna"
					>
						<ValidationProvider
							v-slot="{ errors }"
							name="Endereço"
							rules="required|max:100"
						>
							<b-field
								label="Endereço"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input 
									v-model="form.endereco"
									data-testeid="endereco"
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div
						v-if="hasSearch && !form.hasNeighborhood"
						class="coluna"
					>
						<ValidationProvider
							v-slot="{ errors }"
							name="Bairro"
							rules="required|max:100"
						>
							<b-field
								label="Bairro"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input 
									v-model="form.bairro"
									data-testeid="bairro"
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div
						v-if="hasSearch"
						class="coluna coluna-numero"
					>
						<ValidationProvider
							v-slot="{ errors }"
							name="Número"
							rules="max:10"
						>
							<b-field
								label="Número"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input
									v-model="form.number"
									v-mask="['#####']"
									data-testeid="numero"
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div
						v-if="hasSearch"
						class="coluna"
					>
						<ValidationProvider
							v-slot="{ errors }"
							name="Complemento"
							rules="max:40"
						>
							<b-field
								label="Complemento"
								:message="errors[0]"
								:type="errors.length > 0 ? 'is-danger' : ''"
							>
								<b-input 
									v-model="form.complement"
									data-testeid="complemento"
								/>
							</b-field>
						</ValidationProvider>
					</div>
					<div
						v-if="hasSearch"
						class="coluna view-address"
					>
						{{ (form.endereco !== null && form.endereco.length > 0) ? form.endereco + ',' : '' }}
						{{ (form.number && form.number.length > 0) ? form.number : 'S/N, ' }}
						{{ (form.complement && form.complement.length > 0) ? form.complement + ', ' : '' }}
						{{ (form.bairro !== null && form.bairro.length > 0) ? form.bairro + ',' : '' }}
						{{ form.cidade }} - {{ form.uf }}
					</div>
				</div>
			</fieldset>
		</form>
	</ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
	name: 'FormEnderecoComponentEBRADI',
	components: {
		ValidationProvider,
		ValidationObserver
	},
	props: {
		endereco: {
			type: Object,
			required: false
		},
		label: {
			type: String,
			required: true,
			default: 'Endereço'
		},
		testeid: {
			type: String,
			required: false,
			default: ''
		}
	},
	data() {
		return {
			loading: false,
			hasSearch: false,
			zipcodeSearched: '',
			errorCep: '',
			form: {
				zipcode: null,
				number: null,
				complement: null,
				uf: null,
				estado: null,
				esta_id: null,
				cidade: null,
				cida_id: null,
				bairro: null,
				endereco: null,
				hasSearched: false,
				hasAddress: false,
				hasNeighborhood: false,
			}
		}
	},
	watch: {
		'form.zipcode'(value) {
			this.errorCep = '';

			if (value.length === 9 && !this.form.hasSearched) {
				this.loading = true;
				this.hasSearch = false;

				this.form.hasAddress = false;
				this.form.hasNeighborhood = false;

				this.getEndereco(value.replace(/\D/g,''))
					.then((res) => {
						if (res.status === 'success' && res.message.cep !== '') {
							this.zipcodeSearched = res.message.cep;
							this.hasSearch = true;
							this.form.uf = res.message.cep_estado;
							this.form.estado = res.message.cep_estado;
							this.form.esta_id = res.message.esta_id;
							this.form.cidade = res.message.cep_cidade;
							this.form.cida_id = res.message.cida_id;
							this.form.bairro = res.message.cep_bairro;
							this.form.endereco = res.message.cep_endereco;

							if (this.form.bairro !== null && this.form.bairro.length > 0) {
								this.form.hasNeighborhood = true;
							}

							if (this.form.endereco !== null && this.form.endereco.length > 0) {
								this.form.hasAddress = true;
							}
						} else {
							this.clear();
							this.errorCep = 'CEP Inválido';
							this.hasSearch = false;
						}
					})
					.catch(() => this.clear())
					.finally(() => this.loading = false)
			}

			if( this.form.hasSearched ) {
				this.form.hasSearched = false;
			}
		}
	},
	mounted() {
		if (this.endereco) {
			this.form.zipcode = this.endereco.zipcode;
			this.form.number = this.endereco.number;
			this.form.complement = this.endereco.complement;
			this.form.uf = this.endereco.uf;
			this.form.estado = this.endereco.estado;
			this.form.esta_id = this.endereco.esta_id;
			this.form.cidade = this.endereco.cidade;
			this.form.cida_id = this.endereco.cida_id;
			this.form.bairro = this.endereco.bairro;
			this.form.endereco = this.endereco.endereco;
			this.form.hasSearched = true;
			this.hasSearch = true;
			this.form.hasNeighborhood = this.endereco.hasNeighborhood;
			this.form.hasAddress = this.endereco.hasAddress;
		}
	},
	methods: {
		setData(key, value) {
			if (Object.keys(this.form).includes(key)) {
				this.form[key] = value;
			}
		},
		getAllData() {
			return this.form;
		},
		getData(key) {
			if (Object.keys(this.form).includes(key)) {
				return this.form[key];
			}
		},
		async validations() {
			return await this.$refs.form_endereco.validate();
		},
		clear() {
			this.form.uf = null;
			this.form.estado = null;
			this.form.esta_id = null;
			this.form.cidade = null;
			this.form.cida_id = null;
			this.form.bairro = null;
			this.form.endereco = null;
			this.hasSearch = false;
			this.hasAddress = false;
			this.bairro = false;
		},
		...mapActions('ebradi', [
			'getEndereco',
		]),
	}
}
</script>

<style lang="scss" scoped>
.form-endereco {
	margin: 84px 0px;

	h2 {
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 30px;
	}

	&::v-deep .icon.is-right.has-text-danger {
		display: none!important;
	}

	.view-address {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		text-align: left;
	}
}


@media screen and (max-width: 900px)  {
	.form-endereco {
		margin: 42px 0;

		.titulo {
			font-size: 14px;
		}
	}

	.view-address {
		width: 100%;
		max-width: 100%;
	}

	.colunas-inputs {
		.coluna {
			max-width: 100%;
			width: 100%;

			&:nth-child(1), &.coluna-numero {
				order: -1;
				width: calc(50% - 12px);
			}
		}
	}
}

</style>
